@tailwind base;
@tailwind components;
@tailwind utilities;

.blurred {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none;
    color: transparent;
    text-shadow: 0 0 12px rgba(0,0,0,0.5);
}
